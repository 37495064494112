import * as React from "react"
import Layout from "../../components/v2/layout"
import Seo from "../../components/seo"
//Components

import Main from '../../components/v2/Home/main'
import Legado from '../../components/v2/Home/legado'
import Desarrollos from '../../components/v2/Home/developments'
import About from '../../components/v2/Home/about'
import Contacto from '../../components/v2/global_contact'
import Novedades from '../../components/v2/Home/novelties'

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
      
  return(
  <Layout>
    <Seo title="Creciendo junto al campo desde 1867" description="Más de 150 años de experiencia en el sector rural. Conozca los campos más destacados de la Argentina, servicio de tasación satelital, divisiones y consultoría."/>
    <Main />
    <Legado />
    <Desarrollos />
    <About />
    <Novedades />
    <Contacto />
  </Layout>
)}

export default IndexPage

import React from "react"
import Image from "../../../images/bullrich-campos.png"
import { Link } from "gatsby"

const About = () => {
  return (
    <section className="about-home d-flex align-items-center">
      <img src={Image} alt="Portada de la sección de nosotros" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2 className="color-2 text-center">
              Escuchamos con orgullo la experiencia{" "}
              <br className="d-none d-lg-block" /> de quienes nos eligen
            </h2>
          </div>
          <div className="col-lg-4 mb-lg-0 mb-5">
            <p className="mb-lg-4 mb-2">
              “Desde que trabajamos con Bullrich Campos, hemos encontrado campos{" "}
              de alta calidad y valor en el mercado local, que de otra manera{" "}
              hubieran sido difícil de encontrar. Su experiencia y conocimiento{" "}
              son excepcionales”.
            </p>
            {/* <p className="bold"> - Productor Local</p> */}
          </div>
          <div className="col-lg-4 mb-lg-0 mb-5">
            <p className="mb-lg-4 mb-2">
              “Bullrich Campos nos proporcionó una experiencia personalizada y{" "}
              de alta calidad en la compra de nuestro campo. Su equipo de{" "}
              profesionales nos asesoró en todo el proceso y ayudó a encontrar{" "}
              la propiedad perfecta para nuestra empresa”.{" "}
            </p>
            {/* <p className="bold"> - Empresa agropecuaria familiar</p> */}
          </div>
          <div className="col-lg-4 mb-lg-0 mb-5">
            <p className="mb-lg-4 mb-2">
              “Confiamos en Bullrich Campos para encontrar las mejores{" "}
              oportunidades de inversión en el mercado inmobiliario rural. Su{" "}
              experiencia y conocimiento son inigualables”.{" "}
            </p>
            {/* <p className="bold">- Empresa de agronegocios</p> */}
          </div>
          <div className="col-12 d-flex justify-content-center">
          <Link to="/servicios" className="mx-auto btn btn-black">Conozca nuestros servicios</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About

import React from "react"
import { connect } from "react-redux"
import { getThree, orderbyDate, getFeatured, getNotFeatured } from "../../../helpers/helper.novelty"
import NoveltyCard from "../novelty-card"
import { Link } from "gatsby"
import { useGetBlogsQuery } from "../../../redux/mediacore/blog"

const Novedades = ({ tags}) => {

  const { data: allBlogData, isLoading, isError } = useGetBlogsQuery(26);

  const featuredFirst = (data) => {
    let nov = [];
    if (getFeatured(data)?.length > 0) {
        nov.push(...orderbyDate(getFeatured(data)))
        nov.push(...orderbyDate(getNotFeatured(data)))
        return nov
    } else {
        return data
    }
}

  return allBlogData?.news?.filter(element => element.featured === false && !element.tags.find(t => t.name.toLowerCase() === 'circular') ).length === 0 && !isLoading ? '' :(
    <section className="novedades-home">
      <div className="container-fluid">
        <h2 className="pb-5 white text-center">Novedades</h2>
      </div>
      <div className="d-flex flex-column flex-lg-row">
        {isLoading 
        ? [1,2,3].map(() =>(
          <NoveltyCard skeleton={true}/>
        )) 
        : getThree(featuredFirst(allBlogData?.news)).map((n, i) => (
          <NoveltyCard novelty={n} key={i}/>
        ))}
      </div>
      <div className="footer-container d-flex justify-content-center">
        <Link to="/novedades" className="btn white border">Ver más novedades</Link>
      </div>
    </section>
  )
}

export default connect(
  state => ({
    loading_novelties: state.novelties.loading_novelties,
    novelties: state.novelties.novelties,
    tags: state.novelties.tags,
  }),
  null
)(Novedades)

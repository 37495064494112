import React from "react"
import Slider from "react-slick"
import { connect } from "react-redux"
import { getFeatured } from "../../../helpers/helper.developments"
import { NextArrow, PrevArrow } from "../arrows.jsx"
import DevelopmentCard from "../card-develpments"
import { Link } from "gatsby"
const settingsSlick = {
  dots: false,
  infinite: true,
  speed: 500,
  fade: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

const Developments = ({ developments }) => {
  return developments?.data ? (
    <section className="desarrollos-home white">
      <div className="container-fluid flex-column d-flex justify-content-center">
        <h2 className="text-center mb-lg-5 mb-5">Campos destacados</h2>
        <div className="row justify-content-center default-row">
          <div className="col-lg-8 col-resize">
            {(
              <Slider {...settingsSlick}>
                {getFeatured(developments?.data?.objects).map((d, i) => (
                  <DevelopmentCard d={d} i={i} isItem={true}/>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
      <div className="footer-container d-flex justify-content-center">
        <Link to="/seleccion" className="btn white border">Conozca nuestra selección</Link>
      </div>
    </section>
  ):''
}
export default connect(
  state => ({
    developments: state.developments.developments,
  }),
  null
)(Developments)

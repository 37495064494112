import React from "react"
import Image from "../../../images/top-home.webp"
import BannerTop from "../../v1/Global/Modules/MediaPush/BannerTop";
const Main = () => {
  
  function scrollTo(id) {
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset - 9 * 16 + 100;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  return (
    <section className="banner-top home">
      <img src={Image} className="video" alt="Imagen de portada video" />
      <BannerTop page={"Ini"}/>
      <div className="content text-center">
        <p className="white">Bienvenidos</p>
        <h1 className="white my-lg-3">Bullrich Campos</h1>
        <p className="white spacing">CRECIENDO JUNTO AL CAMPO DESDE 1867</p>
      </div> 
      <div className="position-absoute scroll-position">
        <div onClick={() => scrollTo("legado-home")} className="icon-scroll"></div>
      </div>
    </section>
  )
}

export default Main

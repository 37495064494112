import React from "react"
import Image from "../../images/final-home.png"
import { Link } from "gatsby"

const Contacto = () => {
  return (
    <section className="contacto-home">
      <img src={Image} alt="Portada de seccion contacto" />
      <div className="container d-flex align-items-center flex-column">
        <h2 className="text-center white">
          Más de 150 años de experiencia en el sector
        </h2>
        <p className="text-center white">
          Una historia que se escribe con la tierra, una herencia de
          conocimiento y pasión por el campo. Descubra nuestro{" "}
          <br className="d-none d-lg-block" /> legado de seis generaciones y
          permítanos acompañarlo con el mejor y más especializado servicio del
          mercado. <br className="d-none d-lg-block" /> Lo invitamos a conocer
          los campos más destacados de Argentina y a recibir nuestra atención
          personalizada.
        </p>
        <Link to="/contacto" className="btn white border mx-auto text-center">
          Contactenos
        </Link>
      </div>
    </section>
  )
}

export default Contacto

import React from "react"
import Image from "../../../images/legado/equipo2.webp"
import Flowers from "../../../images/flowers.svg"
import FirmaSvg from "../../../images/roberto-firma.svg"
import { Link } from "gatsby"
const Legado = () => {
  return (
    <section className="legado-home d-flex flex-lg-row flex-column align-items-center" id="legado-home">
      <img className="d-none d-lg-block" src={Image} alt="Roberto F. Santillan" />
      <div className="content d-flex flex-column justify-content-center">
        <p className="color-2">VISIÓN Y COMPROMISO</p>
        <h2 className="color-2">Orgullosos de ser referentes del sector</h2>
        <div className="text">
          <p className="color-2">
            “Nos sentimos honrados y agradecidos de haber obtenido el primer{" "}
            <br className="d-none d-lg-block" />
            puesto en el Ranking de Reporte Inmobiliario y Revista Mercado
            durante <br className="d-none d-lg-block" />
            ocho años consecutivos, en el segmento inmobiliaria rural. Nuestro{" "}
            <br className="d-none d-lg-block" />
            éxito se debe a la combinación de tradición, excelencia e innovación{" "}
            <br className="d-none d-lg-block" />
            constante. Gracias a la experiencia de seis generaciones, nos{" "}
            <br className="d-none d-lg-block" />
            adaptamos rápidamente y nos mantenemos a la vanguardia, enfocados en{" "}
            <br className="d-none d-lg-block" />
            brindar una experiencia exclusiva para satisfacer las demandas más{" "}
            <br className="d-none d-lg-block" />
            exigentes de nuestros clientes.“
          </p>
          <FirmaSvg className="position-abolute flower d-none d-lg-block"/>
        </div>
        <Link to="/legado" className="btn btn-black mt-auto">
          Conozca nuestro legado
        </Link>
        {/* <img className="position-abolute flower d-none d-lg-block" src={Flowers} alt="" /> */}
        <Flowers className="position-abolute flower bottom d-none d-lg-block" />
      </div>
    </section>
  )
}

export default Legado

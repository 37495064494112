import React from "react"
import { getCover, getQuality, getTextShort } from "../../helpers/helper.rendering"
import { getName } from "../../helpers/helper.developments"
import { getLocation } from "../../helpers/helper.properties"
import { Link, navigate } from "gatsby"
import { Redirect } from "@reach/router"

const DevelopmentCard = ({d,i,isItem=false,aos=''}) => {

  const redirectMobile = (development) => {
    if(screen.width < 992){
      return navigate("/seleccion/"+ getName(development).replaceAll(" ","-").toLowerCase() + "/" + development.id)
    }
  }

  return (
    <div onClick={() => redirectMobile(d)} key={i} class={`aos-init card card-development white ${isItem ? 'item' : ''}`}  data-aos={aos}>
      <img
        src={getQuality(getCover(d.photos))}
        alt="Portada de imagen emprendimiento"
        className="object-cover object-center cursor-pointer"
      />
      <div className="content">
        <p className={"has bold text-uppercase " + (!d?.custom_tags?.find(tag => tag.name.includes("Ha.")) ? 'd-none' : '')}>{d?.custom_tags?.find(tag => tag.name.includes("Ha."))?.name}</p>
        <h3 className="title mb-0">{getName(d)}</h3>
        <div className="location small text-uppercase">{getLocation(d)}</div>
        <div className="onHover d-flex align-items-center justify-content-between">
          <p>
            {getTextShort(d.description,200)}
            {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Enim quam
            quaerat ex, pariatur labore blanditiis expedita fuga obcaecati ullam
            dolorem dolorum veniam quia eveniet voluptatem incidunt atque
            aliquid sint ipsum? */}
          </p>
          <Link to={"/seleccion/"+ getName(d).replaceAll(" ","-").toLowerCase() + "/" + d.id} className="btn d-none d-lg-block white border">
            <i></i>
            <i></i>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default DevelopmentCard
